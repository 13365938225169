<template>
  <section id="section-content">
    <Modal
      ref="modalReport"
      no-close-on-esc
      no-close-on-backdrop
      :size="relatorioData
        && relatorioData.reportType
        && relatorioData.reportType.type
        ? reportSizes[relatorioData.reportType.type] : 'lg'"
      centered
    >
      <DynamicReport
        :reportProcesso="relatorioData.reportProcesso"
        :reportsMateriais="relatorioData.reportMateriais"
        :reportType="relatorioData.reportType"
      />
    </Modal>
    <div
        class="info-ciclo-card card invision-card borderless p-card div-container p-4"
      >
        <div class="table-header">
          <h5>Materiais do protocolo</h5>
          <b-button
            class="btn-table-header"
            variant="outline-dark"
          >
          <printer-icon v-if="printable && !idProcessoCicloBusy" @click="printRelatorio()"
            class="w30px mouse align-middle" />
          <b-spinner v-else variant="secondary" small label="Spinning"/>

          </b-button>
        </div>

        <b-table
          name="tabela_ciclo"
          striped
          responsive
          :items="material"
          :fields="fields"
          ref="processos-table"
        >
          <template #cell(obs)/>
          <template #cell(detailsMaterialGenerico)/>
          <template #cell(ordem)="{ index }"> {{ index + 1 }}</template>
          <template #cell(status)="form">
            <div v-if="form.item.obs" class="status-base change-color-yellow">
              Liberado
            </div>
            <div v-else-if="form.item.status === null">
            -
            </div>
            <div v-else class="status-base"
              :class="form.item.status === 'Entregue' ? 'change-color-green' :
              form.item.status === 'Forçado' ? 'change-color-yellow' :
              'change-color-grey'"
            >
              {{ form.item.status }}
            </div>
          </template>
          <template #cell(details)="row">
            <img
              style="cursor: pointer;"
              v-if="row.item.isGenerico"
              src="../../../assets/img/clipboard.svg"
              @click="row.toggleDetails"
              class="clipboard-icon"
            />
            <info-icon v-if="row.item.obs"
              class="ml-3 info-icon"
              v-b-tooltip.hover.top="
                row.item.obs
              "
            >
            </info-icon>
          </template>
          <template #row-details="row">
            <b-card>
              <b-row class="mb-2">
                <b-col>Qtd: {{ row.item.detailsMaterialGenerico[0] }}</b-col>
                <b-col>Preparo: {{ row.item.detailsMaterialGenerico[1] }}</b-col>
                <b-col>Esterilização: {{ row.item.detailsMaterialGenerico[2] }}</b-col>
                <b-col>Saída Setor: {{ row.item.detailsMaterialGenerico[3] }}</b-col>
                <b-col v-if="row.item.detailsMaterialGenerico[4] ==='retornoExpurgo'">
                  (Retornou ao Expurgo)
                </b-col>
              </b-row>
            </b-card>
          </template>
        </b-table>
    </div>

  </section>
</template>

<script>

import swal from 'sweetalert';
import Modal from '@/components/Utils/Modal';
import GenericApi from '@/services/genericRequest';
import DynamicReport from '@/processos/report/DynamicReport';

export default {

  components: {
    Modal,
    DynamicReport,
  },

  props: {
    material: {
      type: Array,
    },
    printable: {
      type: Boolean,
    },
    fields: {
      type: Array,
    },
    infoRelatorio: {
      type: Object,
    },
  },

  data() {
    return {
      relatorioData: {},
      idProcessoCicloBusy: null,
      tipoProcesso: null,
      reportSizes: {
        etiqueta: 'md',
        relatorio: 'xl',
        default: 'lg',
      },
    };
  },

  methods: {
    async printRelatorio() {
      try {
        if (this.material[0].descricao) {
          this.idProcessoCicloBusy = true;
          this.tipoProcesso = this.material[0].tipoProcesso.chave;

          const route = `materialProtocolo/printRelatorio/${this.$route.params.protocolo}/${this.$route.params.id}`;
          // Permite enviar flags para verificar se um material rede/inox saiu por outro.
          const opts = {
            check_material_substitution: true,
            used_on_key_process_type: 'saidaArsenal',
          };
          this.relatorioData = await GenericApi.get({ filters: { ...opts } }, route);
          this.$refs.modalReport.show();
        } else {
          swal('Se não conseguir buscar o material de outra forma, gentileza reportar ao suporte.');
        }
      } catch (e) {
        console.log(e);
      } finally {
        this.idProcessoCicloBusy = null;
      }
    },
  },
};
</script>

<style>
@media print {
  body * {
    visibility: hidden;
  }
  #section-content,
  #section-content * {
    visibility: visible;
  }
  #section-content {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    margin-top: -25rem;
  }
}
</style>

<style lang="scss" scoped>
.info-icon {
  color: rgb(167, 103, 51);
  cursor: pointer;
}
$neutral-color-80: #35384d;
.mouse{
  cursor: pointer;
}

.status-base {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  height: 32px;
  font-weight: 600;
  padding: 0.4rem;
}
.change-color-yellow {
  background-color: #ffac686e;
  color: #e67a22;
}
.change-color-grey {
  background-color: #F0F1FC;
  color: #7E829F;
}

.change-color-green {
  background: rgba(115, 230, 207, 0.2);
  color: #21CCA9;
}

.table-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.btn-table-header {
  margin: 0;
}

.info-ciclo-card .invision-btn-light {
  position: absolute;
  right: 36px;
  transform: translate(0, -15%);
}

.font-style-medium {
  font-weight: 600;
  margin-top: 4px;
}

.p-card {
  padding: 36px;
  margin-bottom: 20px;
}

.qr-code-label {
  margin: auto;
  font-style: italic;
  color: #8f8f8f;
}

.printOnly {
  display: none;
}

.card.invision-card.invision-top {
  border-top: 0 !important;
}

h5 {
  // titulo cabeçalho modal
  font-size: 18px;
  color: $neutral-color-80;
}

button {
  margin: auto;
}

.w-52 {
  width: 95%;
}

@media screen and (max-width: 991px) {
  .tabela {
    margin-top: 20px;
  }
}

@media print {
  .no-print {
    display: none;
  }
}
</style>
