<template>
  <div id="section">
    <div id="content">
      <div class="tela">
        <PageTitle :title="title"/>
        <b-row>
          <b-col sm="12" md="12" lg="12" xl="12">
            <b-row class="mt-4 mr-4">
              <b-col>
                <Vouchers :infoRelatorio="protocolData"/>
              </b-col>
            </b-row>
            <b-row class="mt-4 mr-4">
              <b-col>
                <TabelaProcessos
                  :material="material"
                  :printable='printable'
                  :fields="fieldsTabela"
                  :infoRelatorio="protocolData"
                />
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>

import GenericApi from '@/services/genericRequest';
import PageTitle from '@/templates/PageTitle';
import Vouchers from './Vouchers';
import TabelaProcessos from './TabelaProcessos';

export default {
  components: {
    Vouchers,
    TabelaProcessos,
    PageTitle,
  },

  data() {
    return {
      material: [],
      protocolData: {},
      printable: false,
      fieldsTabela: [],
      title: '',
    };
  },
  async mounted() {
    await this.updateMaterial();
    if (this.protocolData) {
      this.printable = true;
    }
  },

  methods: {
    async updateMaterial() {
      const route = `materialProtocolo/visualizar/${this.$route.params.protocolo}/${this.$route.params.id}`;
      // Permite enviar flags para verificar se um material rede/inox saiu por outro.
      const opts = {
        check_material_substitution: true,
        used_on_key_process_type: 'saidaArsenal',
      };

      const protocoloData = await GenericApi.get({ filters: { ...opts } }, route);
      this.material = protocoloData.materiais;
      const { fieldsCabecalho, reportProcesso, titleRelatorio } = protocoloData;
      this.protocolData = { ...reportProcesso, fieldsCabecalho };
      this.fieldsTabela = protocoloData.fieldsTabela;
      this.title = titleRelatorio;
    },

    handleHideModal() {
      // quando o é impresso o modal de relatorio o modal de qrCode é escondido pois estava sobrepondo o relatorio
      // esta função apenas retorna o modal do qrCode
      document.getElementById('qrCodePrinter').style.display = 'inline-block';
    },
  },
};
</script>

<style lang="scss" scoped>
$neutral-color-80: #35384d;

.p-right-stepsList {
  padding: 0 !important;
}

.qrcode {
  background-color: white;
  position: fixed;
  right: 0px;
  top: 0;
  bottom: 0px;
  padding: 24px;
}

.tela {
  width: 95% !important;
  margin-right: auto;
  margin-left: auto;
}

@media (max-width: 4000px) {
  .container {
    max-width: 1750px !important;
  }
}

@media (min-width: 1600px) {
  .container {
    max-width: 1550px;
  }
}

@media (min-width: 1400px) {
  .container {
    max-width: 1350px;
  }
}

.status-row {
  padding-top: 20px;
}
@media (min-width: 1500px) {
  .container {
    max-width: 1480px;
  }
}

.status-tag {
  background-color: rgba(46, 155, 229, 0.3);
  color: #0093e5;
  font-weight: 500;
  border-radius: 5px;
  padding: 2px;
  margin-top: 2px;
  text-align: center;
}

.status-tag-warning {
  background-color: rgba(228, 13, 31, 0.719);
  color: #ffffff;
  font-weight: 500;
  border-radius: 5px;
  width: 80%;
  padding: 2px;
  margin-top: 2px;
}

.font-style-medium {
  font-weight: 600;
  margin-top: 4px;
}

.qr-code-label {
  margin: auto;
  font-style: italic;
  color: #8f8f8f;
}

.printOnly {
  display: none;
}

.p-card {
  padding: 36px;
  margin-top: 20px;
}

.card.invision-card.invision-top {
  border-top: 0 !important;
}

h5 {
  // titulo cabeçalho modal
  font-size: 18px;
  color: $neutral-color-80;
}

button {
  margin: auto;
}

.w-52 {
  width: 95%;
}
@media (min-width: 1200px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    max-width: 1140px;
  }
}

.tabela {
  background-color: white;
}

.main-content {
  width: 40em;
  border: 2px dashed rgb(178, 176, 215);
  border-radius: 5px;
  padding: 5%;
}

.span-text {
  color: #00daae;
}
</style>
