<template>
  <div class="info-ciclo-card card invision-card borderless p-card div-container p-4">
    <b-container>
      <b-row align-h="between" align-v="center">
        <b-col cols="3" v-for="item in infoRelatorio.fieldsCabecalho" :key="item.key">
          <h3 class="label-status-row-style" >{{item.label}}</h3>
          <span>{{ infoRelatorio[item.key]}}</span>
          <br><br>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  props: {
    infoRelatorio: {
      type: Object,
    },
  },
};
</script>

<style lang="scss" scoped>
$neutral-color-80: #35384d;
.arrows {
  transition: 0.3s;
  border-radius: 50%;
}

.setor-origem {
  margin-bottom: 4.7rem;
}

.number-protocol {
  margin-bottom: 2.9rem;
}

.arrows:hover {
  background-color: rgb(230, 230, 230);
}

.arrows:active{
  background-color:  #8f8f8f;
}
.container-arrows{
  width: 50px;
}

.left-button {
  flex: 1;
}

.right-button {
  flex: 1;
  float: right;
}

.encerramento {
  flex: 1;
  margin-top: 10px;
  position: relative;
  right: 0px;
  //justify-content: center;
  text-align: center;
}

.status-encerramento {
  padding-top: 20px;
  text-align: center;
  font-weight: bold;
}

.div-container {
  position: relative;
}
.div-arrow-left {
  position:absolute;
  top: 50%;
  transform: translate(-50%);
  left:0px;
  filter: drop-shadow(4px 4px 20px rgba(61, 70, 85, 0.15));
}

.div-arrow-right {
  position:absolute;
  top: 50%;
  transform: translate(-50%);
  right:-32px;
  filter: drop-shadow(4px 4px 20px rgba(61, 70, 85, 0.15));
}

.div-arrow-left .arrows {
  background: #FFFFFF;
  opacity: 0.8;
  background-position: center;
  transition: background 0.8s;
}

.div-arrow-right .arrows {
  background: #FFFFFF;
  opacity: 0.8;
  background-position: center;
  transition: background 0.8s;
}

.div-arrow-left .arrows:hover {
  background-color:  rgb(230, 230, 230);
  background-position: center;
  transition: background 0.8s;
}

.div-arrow-right .arrows:hover {
  background-color:  rgb(230, 230, 230);
  background-position: center;
  transition: background 0.8s;
}

.ripple:active {
  background-color:  rgb(128, 128, 128);
  background-size: 100%;
  transition: background 0s;
}

.info-ciclo-card .invision-btn-light {
  /* position: absolute;
  right: 36px; */
  transform: translate(0, -15%);
}

.status-row {
  padding-top: 20px;
}

.status-tag {
  background-color: rgba(46, 155, 229, 0.3);
  color: #0093e5;
  font-weight: 500;
  border-radius: 5px;
  padding: 2px;
  margin-top: 2px;
  text-align: center;
}

.status-tag-warning {
  background-color: rgba(228, 13, 31, 0.719);
  color: #ffffff;
  font-weight: 500;
  border-radius: 5px;
  width: 80%;
  padding: 2px;
  margin-top: 2px;
}

.label-status-row-style {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */

  letter-spacing: 0.04em;
  text-transform: uppercase;

  /* Neutral / neutral-color-60 */

  color: #7E829F;
}

.font-style-medium {
  font-weight: 600;
  margin-top: 4px;
}

.printOnly {
  display: none;
}

.card.invision-card.invision-top {
  border-top: 0 !important;
}

h5 {
  // titulo cabeçalho modal
  font-size: 18px;
  color: $neutral-color-80;
}

button {
  margin: auto;
}

.w-52 {
  width: 95%;
}

.tabela {
  background-color: white;
}

@media print {
  .no-print {
    display: none;
  }
}
</style>
